import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import store from "@/store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/Login",
      name: "Login",
      component: () => import("./components/Auth/login"),
      meta: { requiresAuth: false },
    },
    {
      path: "/forgotPassword",
      name: "forgotPassword",
      component: () => import("./components/Auth/forgotPassword"),
      meta: { requiresAuth: false },
    },
    {
      path: "/otpPage",
      name: "otpPage",
      component: () => import("./components/Auth/otpPage"),
      meta: { requiresAuth: false },
    },
    
    
    {
      path: "*",
      name: "404pagenotfound",
      props: true,
      component: () => import("./components/Common/404"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./components/Common/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/",
      redirect: "dashboard",
      name:"AdminDashboard",
      component: () => import("@/layouts/Layout"),
      meta: { requiresAuth: true },
      children: [
        // Components

        {
          name: "Dashboard",
          path: "dashboard",
          component: () =>
            import("./components/Views/Dashboard/basicDashboard"),
        },

        {
          path: "/churchList",
          name: "churchList",
          props: true,
          component: () => import("./components/Views/Church/churchList"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addChurch",
          name: "addChurch",
          props: true,
          component: () => import("./components/Views/Church/addChurch"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/editChurch",
          name: "editChurch",
          props: true,
          component: () => import("./components/Views/Church/editChurch"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/ritesList",
          name: "ritesList",
          props: true,
          component: () => import("./components/Views/Church/ritesList"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/churchView",
          name: "churchView",
          props: true,
          component: () => import("./components/Views/Church/churchView"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/liveTelecast",
          name: "liveTelecast",
          props: true,
          component: () => import("./components/Views/Live Telecast/liveTelecast"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/addLive",
          name: "addLive",
          props: true,
          component: () => import("./components/Views/Live Telecast/addLive"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/bibleQuotes",
          name: "bibleQuotes",
          props: true,
          component: () => import("./components/Views/Church/bibleQuotes"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/holymassView",
          name: "holymassView",
          props: true,
          component: () => import("./components/Views/HolyMass/holymassView"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addWeeklyMass",
          name: "addWeeklyMass",
          props: true,
          component: () => import("./components/Views/HolyMass/addWeeklyMass"),
          meta: {
            requiresAuth: false,
          },
        },
        
        {
          path: "/addSpecialMass",
          name: "addSpecialMass",
          props: true,
          component: () => import("./components/Views/HolyMass/addSpecialMass"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addMonthlyHolyMass",
          name: "addMonthlyHolyMass",
          props: true,
          component: () => import("./components/Views/HolyMass/addMonthlyHolyMass"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/confessionView",
          name: "confessionView",
          props: true,
          component: () => import("./components/Views/Confession/confessionView"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addSpecialConfession",
          name: "addSpecialConfession",
          props: true,
          component: () => import("./components/Views/Confession/addSpecialConfession"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addMonthlyConfession",
          name: "addMonthlyConfession",
          props: true,
          component: () => import("./components/Views/Confession/addMonthlyConfession"),
          meta: {
            requiresAuth: false,
          },
        },


        {
          path: "/adorationView",
          name: "adorationView",
          props: true,
          component: () => import("./components/Views/Adoration/adorationView"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addSpecialAdoration",
          name: "addSpecialAdoration",
          props: true,
          component: () => import("./components/Views/Adoration/addSpecialAdoration"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/addMonthlyAdoration",
          name: "addMonthlyAdoration",
          props: true,
          component: () => import("./components/Views/Adoration/addMonthlyAdoration"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/addBibleQuotes",
          name: "addBibleQuotes",
          props: true,
          component: () => import("./components/Views/Church/addBibleQuotes"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/blessingsList",
          name: "blessingsList",
          props: true,
          component: () => import("./components/Views/Blessing/blessingsList"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/blessingSingleView",
          name: "blessingSingleView",
          props: true,
          component: () => import("./components/Views/Blessing/blessingSingleView"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/saintList",
          name: "saintList",
          props: true,
          component: () => import("./components/Views/Saints/saintList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/addSaint",
          name: "addSaint",
          props: true,
          component: () => import("./components/Views/Saints/addSaint"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/languageList",
          name: "languageList",
          props: true,
          component: () => import("./components/Views/Language/languageList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/countryList",
          name: "countryList",
          props: true,
          component: () => import("./components/Views/Country/countryList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/dioceseList",
          name: "dioceseList",
          props: true,
          component: () => import("./components/Views/Diocese/dioceseList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/parishTypeList",
          name: "parishTypeList",
          props: true,
          component: () => import("./components/Views/Parish Type/parishTypeList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/assignParishManager",
          name: "assignParishManager",
          props: true,
          component: () => import("./components/Views/ParishManagers/assignParishManager"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/singleViewManagers",
          name: "singleViewManagers",
          props: true,
          component: () => import("./components/Views/ParishManagers/singleViewManagers"),
          meta: {
            requiresAuth: false,
          },
        },
        

        
        {
          path: "/subAdminList",
          name: "subAdminList",
          props: true,
          component: () => import("./components/Views/SubAdmin/subAdminList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/pendingChurch",
          name: "pendingChurch",
          props: true,
          component: () => import("./components/Views/SubAdmin/pendingChurch"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/addSubAdmin",
          name: "addSubAdmin",
          props: true,
          component: () => import("./components/Views/SubAdmin/addSubAdmin"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/singleViewSubadmin",
          name: "singleViewSubadmin",
          props: true,
          component: () => import("./components/Views/SubAdmin/singleViewSubadmin"),
          meta: {
            requiresAuth: false,
          },
        },

        
        {
          path: "/logs",
          name: "logs",
          props: true,
          component: () => import("./components/Views/Logs/logs"),
          meta: {
            requiresAuth: false,
          },
        },

        {
          path: "/singleLog",
          name: "singleLog",
          props: true,
          component: () => import("./components/Views/Logs/singleLog"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/about",
          name: "about",
          props: true,
          component: () => import("./components/Views/About/about"),
          meta: {
            requiresAuth: false,
          },
        },
        

        {
          path: "/eventsList",
          name: "eventsList",
          props: true,
          component: () => import("./components/Views/Special Events/eventsList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/userList",
          name: "userList",
          props: true,
          component: () => import("./components/Views/User/userList"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/userDetails",
          name: "userDetails",
          props: true,
          component: () => import("./components/Views/User/userDetails"),
          meta: {
            requiresAuth: false,
          },
        },
       
        {
          path: "/changePasswordSubAdmin",
          name: "changePasswordSubAdmin",
          props: true,
          component: () => import("./components/Views/Profile/changePasswordSubAdmin"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/changePasswordAdmin",
          name: "changePasswordAdmin",
          props: true,
          component: () => import("./components/Views/Profile/changePasswordAdmin"),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/privacyPolicy",
          name: "privacyPolicy",
          props: true,
          component: () => import("./components/Views/privacyPolicy"),
          meta: {
            requiresAuth: false,
          },
        },
        

        
      ],
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token")) {
    // var newURL = ''
    // if (localStorage.getItem('role') == 'employee')
    //     newURL = "/authenticate/user";

    // else
    //     newURL = "authenticate/admin";
    axios({
      method: "GET",
      url: "/token/authenticate",
      headers: {
        token: localStorage.getItem("token"),
      },
    }).then((response) => {
      if (!response.data.status) {
        console.log("router logout");
        store.state.isLoggedIn = false;
        localStorage.clear();

        return;
      }
    });
  }

  if (
    to.matched.some((route) => route.meta.requiresAuth) &&
    !store.state.isLoggedIn
  ) {
    next({ name: "Login"});
    return;
  }
  if (to.name == "Login" && store.state.isLoggedIn == true) {
    next({name: "AdminDashboard"});
    return;
  }
  next();
});

export default router;
